<template>
  <v-card :key="modalKey">
    <v-toolbar color="#c23b78" dark>
      <v-toolbar-title>
        Demande de diffusion 
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="closeModal">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- formulaire de demande de diffusion de repo -->
        <v-row class="mx-2 pt-2">
          <v-col cols="6"
          style="max-height: 72vh; overflow-y: auto;"
          >
            <v-row>
              <v-col cols="12" sm="12" class="px-0 pb-0">
                <v-card-title class="pl-0 pt-0 subtitle-2">Informations</v-card-title>
              </v-col>
              <v-col cols="6" sm="6" class="py-0">
                <v-autocomplete
                    ref="userSelect"
                    :items="users"
                    :item-text="item => `${item.firstname} ${item.lastname}`"
                    :label="'Développeurs*'"
                    v-model="usersSelected"
                    outlined
                    dense
                    append-icon="mdi-account"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.firstname + ' ' + data.item.lastname }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstname + ' ' + data.item.lastname }}
                    </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" class="py-0">
                <v-menu offset-y 
                  :close-on-content-click="false" 
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field 
                      :value="formattedDateTime"
                      label="À diffuser le*"
                      v-on="on"
                      outlined
                      dense
                      append-icon="mdi-calendar"
                    >
                      <!-- {{ formattedDateTime }} -->
                    </v-text-field>
                  </template>
                  <v-date-picker  
                    v-model="requestDate" 
                    :locale="$i18n.locale" 
                    color="primary"
                    ></v-date-picker>
                    <v-time-picker 
                    v-model="requestTime" 
                    format="24hr"
                    scrollable
                  >
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="6" class="pt-0">
                <v-select
                  v-model="branchSelected"
                  :items="branch"
                  label="Nom de la branche*"
                  outlined
                  dense
                  append-icon="mdi-source-branch"
                  color="primary"
                  :rules="[branchRule]"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="6" class="pt-0">
                <!-- possibilité d'indiquer si un fichier d'installation est nécessaire et, si oui, mettre le nom. -->
                <v-checkbox 
                  v-if="!checkedFile"
                  v-model="checkedFile"
                  label="Fichier d'installation"
                  color="primary"
                  class="pt-0"
                ></v-checkbox>
                <v-col cols="12" sm="12" class="pa-0 d-flex">
                  <v-col cols="2" sm="2" class="pt-0">
                    <v-btn
                      icon
                      v-if="checkedFile"
                      color="red"
                      @click="checkedFile = false"
                    > 
                      <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10" sm="10" class="pa-0">
                    <v-text-field
                      v-if="checkedFile"
                      v-model="fileInstallation"
                      label="Fichier d'installation"
                      outlined
                      dense
                      append-icon="mdi-file"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
            <v-text-field 
            class="pl-0 pt-0 subtitle-2"
            v-model="search"
              label="Rechercher un module, sous module ou lien git"
              outlined
              dense
              append-icon="mdi-magnify"
              @input="searchModule"
            >Modules, sous modules et lien git</v-text-field> 
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              class="center-loader"
            ></v-progress-circular>
            <vue-scroll>
              <!-- element selectionner -->
              <span class="pl-2 black--text">{{ moduleSelected && moduleSelected.length > 1 ? 'Éléments' : 'Élément' }} selectionner : {{ selectedModules && selectedModules.length }}</span>
              
              <v-treeview v-if="!loading"
                :items="formatedData"
                v-model="moduleSelected"
                selected-color="primary"
                :rules="[treeviewRule]"
                >
              <!-- retirer checbox des parent de noeux -->
                <template v-slot:prepend="{ item, open }">
                  <v-icon v-if="item.children" @click="open ? $emit('close') : $emit('open')" color="primary">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <v-icon v-else-if="!item.children" color="#c23b78">
                    mdi-file-document
                  </v-icon>
                </template>
                <!-- Ajouter une checkbox sur les derniers enfants -->
                <template v-slot:label="{ item }"> 
                  <v-checkbox
                    v-if="!item.children"
                    v-model="item.checked"
                    :label="item.name"
                    :color="item.checked ? 'primary' : 'grey'"
                    dense
                    @change="updateCheckboxState(item)"
                  ></v-checkbox>
                  <span v-else>{{ item.name }}</span>
                </template>
              </v-treeview>
            </vue-scroll>
          </v-col>

          <v-col cols="6" sm="6">
          <!-- select un package existant -->
          <div v-if="savedConfigs.length > 0">
            <v-card-title class="pl-0 pt-0 subtitle-2">Configuration existante</v-card-title>
            <v-select
              v-model="selectedConfig"
              :items="savedConfigsFormatted"
              label="Configuration existante"
              outlined
              dense
              append-icon="mdi-content-save"
            ></v-select>
          </div>
            
            <v-card-title class="pa-0 pb-2 subtitle-2">Liste des bases</v-card-title>
            <client-picker 
              :class="!checkedConfig ? 'mb-2' : ''"
              style="max-height: 65vh; overflow-y: auto;"
              :style="savedConfigs.length > 0 ? 'max-height: 60vh; overflow-y: auto;' : ''"
              v-model="baseList">
            </client-picker> 
            <!-- chexbox pour demander de sauvegarder tout les choix cohé dans un tabeau -->
            <v-alert type="info"  dense v-if="!checkedConfig" shaped color="indigo">
              <v-checkbox class="pa-0 ma-0"
                v-model="checkedConfig"
                label="Sauvegarder la configuration ?" 
                dense
                hide-details
              ></v-checkbox> 
            </v-alert>
            <div v-if="checkedConfig"> 
              <v-col cols="10" sm="10" class="d-flex">
                  <v-btn
                    icon
                    v-if="checkedConfig"
                    color="red"
                    @click="checkedConfig = false"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                <v-text-field
                  v-model="configName"
                  label="Nom de la configuration"
                  outlined
                  dense
                  append-icon="mdi-content-save"
                  hide-details
                ></v-text-field>
              
              </v-col>
            </div>
          </v-col> 
        </v-row>
      
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue-darken-1" variant="text" @click="closeModal">
        Annuler
      </v-btn>
      <v-btn color="success" variant="text" @click="saveAndCloseModal" :disabled="!isValidForm">
        Créer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import DiffusionService from "@/Services/SupportVision/DiffusionService"

export default {
  name: 'Create',
  components: {
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker")
  },
  data() {
    return {
      modalKey: 0, 
      dataModule: [],
      dataSousModule: [],
      users: [],
      search: '',
      loading: false,
      usersSelected: [],
      moduleSelected: [],
      checked: false,
      checkedFile: false,
      branchSelected: "",
      fileInstallation: "",
      formatedData: [],
      requestDate: moment().format('YYYY-MM-DD'),
      requestTime: moment().format('HH:mm'),
      baseList: [], 
      branch: ["master", "preprod", "dev"],
      branchRule: (v) => !!v || 'Champ requis',
      treeviewRule: (v) => !!v || 'Champ requis',
      checkedConfig: false,
      configName: "",
      selectedConfig: "",
      savedConfigs: [],
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  }, 
  watch: { 
    selectedConfig: {
      handler: function (val) {
        if (val) {
          const config = this.savedConfigs.find((config) => config._id === val);
          this.usersSelected = config.config.devMail;
          this.moduleSelected = config.config.module;
          this.baseList = config.config.baseList;
          this.branchSelected = config.config.branch;
          this.fileInstallation = config.config.fileInstallation;
          this.checkedFile = config.config.isCheck;
          // Mettre à jour l'état des cases à cocher
        this.updateCheckedState();
        }
      },
      immediate: true
    },
    modalKey() {
      this.loadData();
    }
  },
  computed: {
     ...Vuex.mapState(['informations']), 
    formattedDateTime() {
      return moment(this.requestDate).format('DD/MM/YYYY') + ' ' + this.requestTime;
    },
    isValidForm() {
      return !!this.usersSelected && !!this.branchSelected && !!this.checked;
    },
    selectedModules() {
      let selectedModules = [];
      this.formatedData.map((module) => {
        module.children.map((sousModule) => {
          sousModule.children.map((n ) => {
            if (n.checked) {
              selectedModules.push(n.id);
            }
          })
        })
      });
      return selectedModules;
    },
    savedConfigsFormatted() {
      return this.savedConfigs.map((config) => {
        return {
          text: config.config.package,
          value: config._id
        }
      });
    }
  },
  methods: {
    closeModal() {
      this.restForm();
      this.$emit('close-modal');
    }, 
    updateCheckboxState(item) {
      // Vérifiez l'état de la case à cocher
      this.checked = item.checked;
    },
    updateCheckedState() {
      // trouver les modules selectionnés sans les sous modules
      const selectedModules = this.moduleSelected.map((module) => module);
      this.formatedData.map((module) => {
        module.children.map((sousModule) => {
          sousModule.children.map((n) => {
            if (selectedModules.includes(n.id)) {
              n.checked = true;
            }
          });
        });
      });
      
    },
    // fonction de recherche de module,sous module et lien git 
    searchModule() {
      this.formatedData = this.dataModule.map((module) => {
        return {
          id: module._id,
          name: module.module.module + ' :',
          children: module.sous_module.map((sousModule) => {
            return {
              id: sousModule.idSousModule,
              name: sousModule.sous_module + ' - V' + sousModule.version,
              children: [
                {
                  id: sousModule.sousModuleGit,
                  name: sousModule.sousModuleGit,
                  checked: this.checked
                }
              ]
            }
          }) 
        }
      });
      this.formatedData = this.formatedData.filter((module) => {
        return module.name.toLowerCase().includes(this.search.toLowerCase()) || module.children.some((sousModule) => {
          return sousModule.name.toLowerCase().includes(this.search.toLowerCase()) || sousModule.children.some((n) => {
            return n.name.toLowerCase().includes(this.search.toLowerCase());
          });
        });
      });
    },
    async saveAndCloseModal() {
      // Validation de la date et l'heure
      const selectedDateTime = moment(this.requestDate + ' ' + this.requestTime);
      const currentDateTime = moment();
      const minDateTime = currentDateTime.add(1, 'hours').add(30, 'minutes');

      if (selectedDateTime.isBefore(minDateTime)) {
        this.$nError('La date et l\'heure de diffusion doivent être au moins 1 heure et 30 minutes dans le futur.');
        return;
      }
      // Validation de la sélection d'au moins une base
      if (this.baseList.length === 0) {
        this.$nError('Veuillez sélectionner au moins une base.');
        return;
      }
      const data = {
        user: this.usersSelected,
        module: this.selectedModules,
        baseList: this.baseList,
        branch: this.branchSelected,
        devMail: this.informations.emailAddress,
        // la request date
        requestDate: this.formattedDateTime,
        fileInstallation: this.fileInstallation && this.fileInstallation.trim() ? this.fileInstallation : null,
        isCheck: this.checkedFile
      }; 
      // si la configuration est coché on sauvegarde la configuration
      if (this.checkedConfig === true) {
        const config = {
          package: this.configName,
          module: this.selectedModules,
          baseList: this.baseList,
          branch: this.branchSelected,
          devMail: this.informations.emailAddress,
          fileInstallation: this.fileInstallation && this.fileInstallation.trim() ? this.fileInstallation : null,
          isCheck: this.checkedFile
        };
      const res = await DiffusionService.saveConfig(config); 
      this.savedConfigs = res
      } 
      const res = await DiffusionService.createDiffusion(data);
      if (res.success) {
        this.restForm();
        this.$emit('close-modal');
        this.$emit('reload');
        this.$nSuccess(res.message);
      } else {
        this.$nError(res.message);
      }
    },
    restForm() {
      this.usersSelected = [];
      this.moduleSelected = [];
      this.baseList = [];
      this.requestDate = moment().format('YYYY-MM-DD');
      this.requestTime = moment().format('HH:mm');
      this.branchSelected = '';
      this.modalKey += 1;
      this.branchRule = (v) => !!v || 'Champ requis';
      this.treeviewRule = (v) => !!v || 'Champ requis';
      this.checkedConfig = false;
      this.configName = "";
      this.selectedConfig = "";
      this.savedConfigs = [];
      
    },
    async loadData() {
      this.loading = true;
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });
      // recuperer les configurations sauvegardées
      const res = await DiffusionService.getConfigs();
      res && res.length > 0 ? this.savedConfigs = res : this.savedConfigs = [];

      const dataModule = await ModuleService.getModules();
      this.dataModule = dataModule.data; 
      this.dataSousModule = dataModule.data.map((sousModule) => sousModule.sous_module);
      this.formatData();
      this.loading = false; 
      // par defaut on selectionne le user connecté
      this.usersSelected = this.informations.firstname + ' ' + this.informations.lastname;
      
    },
    // formater data pour le v-treeview et afficher les sous modules
    formatData() {
      const uniqueIds = new Set(); // Utiliser un ensemble pour stocker les ids uniques déjà rencontrés
      this.formatedData = this.dataModule.map((module) => {
        let moduleId = module._id;
        if (uniqueIds.has(moduleId)) {
          // Si l'id du module est déjà utilisé, générer un id alternatif unique
          moduleId = `${moduleId}_${Math.random().toString(36).substr(2, 9)}`;
        }
        uniqueIds.add(moduleId); // Ajouter l'id au set des ids uniques

        return {
          id: moduleId,
          name: module.module.module + ' :',
          children: module.sous_module.map((sousModule) => {
            let sousModuleId = sousModule.idSousModule;
            if (uniqueIds.has(sousModuleId)) {
              // Si l'id du sous-module est déjà utilisé, générer un id alternatif unique
              sousModuleId = `${sousModuleId}_${Math.random().toString(36).substr(2, 9)}`;
            }
            uniqueIds.add(sousModuleId); // Ajouter l'id au set des ids uniques

            return {
              id: sousModuleId,
              name: sousModule.sous_module + ' - V' + sousModule.version,
              children: [
                {
                  id: sousModule.sousModuleGit,
                  name: sousModule.sousModuleGit,
                  checked: this.checked
                }
              ]
            }
          }) 
        }
      });
    },
  },
  async mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.center-loader {
  position: absolute;
  top: 50%;
  left: 25%;
}
</style>
